import React, { useState } from 'react'
import { View, Text, TextInput, StyleSheet, Pressable, Image } from 'react-native'
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
import { User, createUser } from '../models/user'
import alert from '../alert'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebaseConfig'
import Links from '../components/Links'

function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
}

const SignupScreen = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const auth = getAuth()

    const signup = () => {
        setError('')
        if (email === '' || validateEmail(email) === false) {
            setError('正しいメールアドレスを入力してください。')
            return
        }
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                sendEmailVerification(userCredential.user, {url: location.href})
                alert('確認メールを送信しました。', 'メール内のリンクをクリックしてください。', [{ text: 'OK', onPress: () => { console.log('OK') } }])
                await createUser(userCredential.user.uid, userCredential.user.email ?? '')
                logEvent(analytics, 'sign_up', { email: email })
            })
            .catch((error) => {
                setError('新規登録に失敗しました')
                logEvent(analytics, 'error', { type: 'sign_up', email, error })
            })
    }
    return (
        <View style={styles.container}>
            <View style={styles.container}>
                <View style={[styles.buttonLayout]}>
                    <Image style={styles.logo} source={require('../assets/kiroku_logo.png')} />
                </View>
                <View style={[styles.inputLayout]}>
                    <TextInput
                        style={[styles.input]}
                        onChangeText={newText => setEmail(newText)}
                        placeholder="email"
                        autoComplete='email'
                        keyboardType='email-address'
                    ></TextInput>
                </View>
                <View style={[styles.inputLayout]}>
                    <TextInput
                        style={[styles.input]}
                        onChangeText={newText => setPassword(newText)}
                        placeholder="password"
                        secureTextEntry={true}
                    ></TextInput>
                </View>
                <Text>パスワードは6文字以上で入力してください</Text>
                <Text>{error}</Text>
                <View style={[styles.buttonLayout]}>
                    <Pressable style={[styles.button]} onPress={signup} >
                        <Text style={[styles.text]}>新規登録</Text>
                    </Pressable>
                </View>
                <Links />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems:'center',
        backgroundColor: 'rgba(0, 172, 168, 0.15)',
        zIndex: -1,
        gap: 10
    },
    buttonLayout: {
        width: '80%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        paddingHorizontal: 32,
        borderRadius: 4,
        elevation: 3,
        color: 'FFFFFF',
        backgroundColor: 'white',
    },
    text: {
        color: '#75655D',
        fontSize: 16,
        fontWeight: '500',
    },
    logo: {
        width: 183,
        height: 186,
        margin: 24
    },
    inputLayout: {
        width: '80%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    input: {
        width: '100%',
        backgroundColor: 'white',
        paddingVertical: 12,
        paddingHorizontal: 12,
        borderRadius: 4,
    },
    link: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    linkText: {
        color: 'blue',
        fontSize: 16,
        fontWeight: '500',
        textDecorationLine: 'underline'
    }
})

export default SignupScreen
