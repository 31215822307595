import React from 'react'
import { StyleSheet } from 'react-native'
import { View, Text } from 'react-native'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '../App'
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import { CreateSubscriptionActions, OnApproveActions, OnApproveData, OnCancelledActions } from "@paypal/paypal-js/types/components/buttons"
import { addSubscription } from '../models/user'
import { plan, planName } from '../models/plan'

type ProfileScreenNavigationProp = NativeStackNavigationProp<
    RootStackParamList,
    'MyPage'
>

const MyPageScreen = () => {
    const navigation = useNavigation<ProfileScreenNavigationProp>()
    const user = useSelector((state: RootState) => state.user)
    if (!user.uid) navigation.navigate('Login')
    const [{ isPending }] = usePayPalScriptReducer()

    const getPlanName = () => {
        return planName[user.plan]
    }

    const createSubscription = async (data: Record<string, unknown>,
        actions: CreateSubscriptionActions) => {
        return actions.subscription.create({
            plan_id: 'P-2XD75431M8887301AM5H6BUA'
        })
    }
    const onApprove = async (data: OnApproveData, actions: OnApproveActions) => {
        // subscriptionIdをuser.subscriptionsに登録する
        if (data.subscriptionID) {
            await addSubscription(user.uid, data.subscriptionID)
            confirm('プラン登録に成功しました。再ログイン後に有効になります。')
        } else {
            alert('プラン登録に失敗しました')
            console.log(data)
        }
    }
    const onError = async (error: any) => {
        alert('プラン登録に失敗しました')
        console.log(error)
    }
    const onCancel = async (data: Record<string, unknown>, actions: OnCancelledActions) => {
        alert('プラン登録がキャンセルされました')
        console.log(data)
    }
    const isShowPaypalButton = () => {
        console.log(user.plan)
        return user.plan === plan.free
    }

    return (
        <View style={styles.container}>
            <View style={styles.textArea}>
                <Text style={styles.text}>メールアドレス</Text>
                <Text style={styles.text}>{ user.email }</Text>
            </View>
            <View style={styles.textArea}>
                <Text style={styles.text}>プラン</Text>
                <Text style={styles.text}>{ getPlanName() }</Text>
            </View>
            <View style={styles.subscriptionArea}>
            {
                isShowPaypalButton() ?
                    isPending
                        ? <div className="spinner" />
                        : <View>
                            <Text>ベーシックプラン加入はこちらから</Text>
                            <PayPalButtons
                                style={{ label: "subscribe", layout: 'horizontal' }}
                                createSubscription={createSubscription}
                                onApprove={onApprove}
                                onError={onError}
                                onCancel={onCancel}
                            />
                        </View>
                    : null}
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: 'rgba(0, 172, 168, 0.15)',
    },
    subscriptionArea: {
        flex: 1,
        height: 50,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    textArea: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 15,
        maxHeight: 50,
        margin: 10
    },
    text: {
        color: '#75655D',
        fontSize: 14,
        fontWeight: '800',
        marginBottom: 16,
        height: 20
    }
})


export default MyPageScreen
