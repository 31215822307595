import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { db } from './../firebaseConfig'
import axios from 'axios'
import { plan, Plan } from './plan'

export interface subscription {
    planId: string
    subscriptionId: string
    status: string
}

export class User {
    constructor(
        readonly uid: string,
        readonly email: string,
        readonly createdAt: Date = new Date(),
        readonly subscriptions: subscription[]
    ) {}
    toString(): string {
        return `uid: ${this.uid}, email: ${this.email}, createdAt: ${this.createdAt.toISOString()}`
    }
}

const userConverter = {
    toFirestore(user: User): DocumentData {
        return {email: user.email, createdAt: user.createdAt, subscriptions: user.subscriptions}
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): User {
        const data = snapshot.data(options)!
        return new User(snapshot.id, data.email, new Date(data.createdAt.seconds * 1000), data.subscriptions ?? [])
    }
}

export const getUser = async (uid: string): Promise<User | null> => {
    const docRef = doc(db, 'users', uid).withConverter(userConverter)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        console.log(docSnap.data())
        return docSnap.data()
    } else {
        return null
    }
}

export const createUser = async (uid: string, email: string) => {
    await setDoc(doc(db, 'users', uid).withConverter(userConverter), new User(
        uid,
        email,
        new Date(),
        []
    ))
}

export const addSubscription = async (uid: string, subscriptionId: string) => {
    const userRef = doc(db, 'users', uid)
    await updateDoc(userRef, { subscriptions: arrayUnion(subscriptionId)})
}

export const getSubscriptionPlan = async (uid: string) => {
    const url = 'https://us-central1-kiroku-ac66a.cloudfunctions.net/getSubscriptionPlan'
    let userPlan: Plan = plan.free
    try {
        const res = await axios.post(url, { uid })
        if (res.data.plan) {
            userPlan = res.data.plan
        }
    } catch (error) {
        console.log(error)
    }
    return userPlan
}
