import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { plan, Plan } from '../../models/plan'

export interface UserState {
    isLogin: boolean
    uid: string
    email: string
    plan: Plan
}

const initialState: UserState = {
    isLogin: false,
    uid: '',
    email: '',
    plan: plan.free,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<Omit<UserState, 'isLogin'>>) => {
            state.isLogin = true
            state.uid = action.payload.uid
            state.email = action.payload.email
            state.plan = action.payload.plan
        },
        logout: (state) => {
            state.isLogin = false
            state.uid = ''
            state.email = ''
            state.plan = plan.free
        },
    },
})

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions

export default userSlice.reducer
