import { DocumentData, GeoPoint, QueryDocumentSnapshot, SnapshotOptions, addDoc, collection, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { db } from '../firebaseConfig'

export class Agreement {
    constructor(
        readonly isAgreed: boolean,
        readonly isConfirmed: boolean | undefined,
        readonly proposalId: string,
        readonly proposedUserId: string,
        readonly proposedUserEmail: string,
        readonly agreedUserId: string,
        readonly agreedUserEmail: string,
        readonly location?: {latitude: number, longitude: number},
        readonly isStandardPlan: boolean = false,
        readonly createdAt: Date = new Date(),
        readonly updatedAt: Date = new Date(),
        readonly id: string = '',
    ) {}
    toString(): string {
        return `
        id: ${this.id},
        isAgreed: ${this.isAgreed},
        isConfirmed: ${this.isConfirmed},
        proposedUserId: ${this.proposedUserId},
        proposalId: ${this.proposalId},
        agreedUserId: ${this.agreedUserId},
        agreedUserEmail: ${this.agreedUserEmail},
        location: ${ this.location ? `(latitude: ${this.location.latitude}, longitude: ${this.location.longitude})` : ''},
        isStandardPlan: ${this.isStandardPlan}
        createdAt: ${this.createdAt.toISOString()}
        updatedAt: ${this.updatedAt.toISOString()}
        `
    }
}

export const agreementConverter = {
    toFirestore(agreement: Agreement): DocumentData {
        const data: any = {
            isAgreed: agreement.isAgreed,
            isConfirmed: agreement.isConfirmed,
            proposedUserId: agreement.proposedUserId,
            proposalId: agreement.proposalId,
            proposedUserEmail: agreement.proposedUserEmail,
            agreedUserId: agreement.agreedUserId,
            agreedUserEmail: agreement.agreedUserEmail,
            createdAt: agreement.createdAt,
            updatedAt: agreement.updatedAt,
            isStandardPlan: agreement.isStandardPlan,
        }
        if (agreement.location) {
            data.location = new GeoPoint(agreement.location.latitude, agreement.location.longitude)
        }
        return data
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Agreement {
        const data = snapshot.data(options)!
        return new Agreement(
            data.isAgreed,
            data.isConfirmed,
            data.proposalId,
            data.proposedUserId,
            data.proposedUserEmail,
            data.agreedUserId,
            data.agreedUserEmail,
            data.location,
            data.isStandardPlan,
            new Date(data.createdAt.seconds * 1000),
            new Date(data.updatedAt.seconds * 1000),
            snapshot.id
        )
    }
}

export const createAgreement = async (userId: string, agreement: Agreement) => {
    const agreements = collection(db, 'users', userId, 'proposals', agreement.proposalId, 'agreements').withConverter(agreementConverter)
    await addDoc(agreements, agreement)
}

export const getAgreements = async (userId: string) => {
    const list: Agreement[] = []
    const proposals = collection(db, 'users', userId, 'proposals')
    const proposalsSnapshot = await getDocs(proposals)
    for (const proposal of proposalsSnapshot.docs) {
        // 最新の1件を取得
        const agreements = collection(db, 'users', userId, 'proposals', proposal.id, 'agreements').withConverter(agreementConverter)
        const querySnapshot = await getDocs(query(agreements, orderBy('createdAt', 'desc'), limit(1)))
        if (querySnapshot.empty) continue
        if (querySnapshot.docs[0].exists()) {
            list.push(querySnapshot.docs[0].data())
        }
    }
    return list.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
}
