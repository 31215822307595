export const plan = {
    free: 'free',
    standard: 'standard',
    trial: 'trial',
} as const
export type Plan = keyof typeof plan

export const planName = {
    free: '無料プラン',
    standard: 'ベーシックプラン',
    trial: 'トライアルプラン',
}
