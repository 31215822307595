import { getCurrentPositionAsync, requestForegroundPermissionsAsync } from 'expo-location'
import alert from '../../alert'

export const getLocation = async () => {
    return await getLocationWrap()
    .then((value) => {
        return value
    })
    .catch((e) => {
        alert('位置情報の取得に失敗しました', e.message, [])
    })
}

export const getLocationWrap = () => {
    return new Promise((resolve: PositionCallback, reject: PositionErrorCallback) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
    })
}
