import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Pressable, Text, ScrollView, ActivityIndicator } from 'react-native'
import CheckBoxText from '../components/CheckBoxText'
import { analytics, db } from '../firebaseConfig'
import { doc, getDoc } from 'firebase/firestore'
import type { RootState } from '../app/store'
import { useSelector } from 'react-redux'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from '../App'
import { getDistance } from '../features/distance/distance'
import { getLocation } from '../features/location/location'
import { createUser, getUser } from '../models/user'
import { Proposal, createProposal, getProposal } from '../models/proposal'
import { Agreement, createAgreement } from '../models/agreement'
import { getPublicDocument } from '../models/public_documents'
import alert from '../alert'
import { logEvent } from 'firebase/analytics'
import { plan } from '../models/plan'

const ALLOWED_DISTANCE = 50

interface IQuestion {
    agreed: boolean
    title: string
    text: string
}

const AgreeScreen = ({route, navigation}: NativeStackScreenProps<RootStackParamList, 'Agree'>) => {
    const user = useSelector((state: RootState) => state.user)
    if (!user.uid) navigation.navigate('Login')
    useEffect(() => {
        const checkLocation = async () => {
            if (route.params && route.params.proposalId && route.params.userId) {
                const location = await getLocation()
                if (!location) {
                    navigation.navigate('Menu')
                    return
                }
                const proposalDocRef = doc(db, 'users', route.params.userId, 'proposals', route.params.proposalId)
                const proposalSnap = await getDoc(proposalDocRef)
                if (proposalSnap.exists()) {
                    const proposal = proposalSnap.data()
                    // 位置情報のチェック
                    const distance = getDistance(location.coords.latitude, location.coords.longitude, proposal.location.latitude, proposal.location.longitude)
                    if (distance > ALLOWED_DISTANCE) {
                        logEvent(analytics, 'error', { type: 'agree', error: `distance: ${distance}` })
                        alert('位置情報が遠すぎます', `${ALLOWED_DISTANCE}メートル以内でQRコードを読み込んでください。\n${distance}メートル離れています`, [{ text: 'OK', onPress: () => {
                            navigation.goBack()
                        }}, { text: 'キャンセル', style: 'cancel', onPress: () => {
                            navigation.goBack()
                        }}])
                        return
                    }
                }
            }
        }
        const getQuestions = async () => {
            setIsLoadiong(true)
            const data = await getPublicDocument('question_items')
            if (data) {
                setQuestions(data.items.map((item: string, index: number) => {
                    return {
                        agreed: false,
                        title: `確認${index + 1}`,
                        text: item
                    }
                }))
            }
            setIsLoadiong(false)
        }
        checkLocation()
        getQuestions()
    }, [])
    const [questions, setQuestions] = useState<IQuestion[]>([])
    const [checkedList, setCheckedList] = React.useState<boolean[]>(questions.map((question) => question.agreed))
    const [isLoading, setIsLoadiong] = React.useState<boolean>(false)
    const handleCheckedChaned = (index: number, checked: boolean) => {
        if (isLoading) return
        // copy
        const newCheckedList = JSON.parse(JSON.stringify(checkedList))
        newCheckedList[index] = checked
        setCheckedList(newCheckedList)
    }
    const handleFinishButtonPressed = async () => {
        if (isLoading) return
        setIsLoadiong(true)
        if (route.params && route.params.proposalId && route.params.userId) {
            const location = await getLocation()
            if (!location) {
                navigation.navigate('Menu')
                return
            }
            // 同意する場合
            const proposal = await getProposal(route.params.userId, route.params.proposalId)
            if (!proposal) return
            // 自分のデータに同意したことを記録
            await createProposal(user.uid, proposal)
            const agreement = new Agreement(
                true,
                false,
                route.params.proposalId,
                route.params.userId,
                proposal.proposedUserEmail,
                user.uid,
                user.email,
                proposal.isStandardPlan ? {latitude: location.coords.latitude, longitude: location.coords.longitude} : undefined,
                proposal.isStandardPlan,
            )
            await createAgreement(user.uid, agreement)

            // 相手のデータに同意したことを記録
            await createAgreement(route.params.userId, agreement)
            logEvent(analytics, 'agreed')
            navigation.navigate('Agreed')
            setIsLoadiong(false)
        } else {
            // 同意提案する場合
            const location = await getLocation()
            if (!location) {
                navigation.goBack()
                return
            }
            const userSnap = await getUser(user.uid)
            if (!userSnap) {
                createUser(user.uid, user.email)
            }
            const proposal = await createProposal(
                user.uid,
                new Proposal(
                    user.uid,
                    user.email,
                    {latitude: location.coords.latitude, longitude: location.coords.longitude},
                    (user.plan === plan.standard || user.plan === plan.trial)
                )
            )
            logEvent(analytics, 'proposal_created')
            navigation.navigate('QRcode', { userId: user.uid, proposalId: proposal?.id ?? '' })
            setIsLoadiong(false)
        }
    }
    return (
        <View style={[styles.container]}>
            {isLoading &&
                <View style={styles.loading}>
                    <ActivityIndicator size='large' />
                </View>
            }
            <ScrollView>
                {questions.map((question, index) => {
                    return (
                        <CheckBoxText handleChecked={handleCheckedChaned} index={index} title={question.title} text={question.text} key={index} />
                    )
                })}
            </ScrollView>
            {checkedList.every((checked) => checked === true) && (
                <View style={styles.finishArea}>
                    <Pressable style={styles.finishButton} onPress={handleFinishButtonPressed}>
                        <Text style={styles.finishText}>確認を完了する</Text>
                    </Pressable>
                </View>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    scrollView: {
        flex: 1,
    },
    finishArea: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    finishButton: {
        flex: 1,
        flexGrow: 0.8,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#00ACA8',
        borderRadius: 4,
    },
    finishText: {
        fontSize: 20,
        fontWeight: '900',
        color: 'white',
        padding: 20,
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default AgreeScreen
